<template>
  <div class="page">
    <van-nav-bar
      fixed
      title="详情"
      left-arrow
      @click-left="navBack"
    ></van-nav-bar>
    <div class="container">
      <van-cell title="方案编号" :value="item.caseId" />
      <van-cell title="方案名称" :value="item.caseName" />
      <van-cell title="方案类别" :value="item.caseType" />
      <van-cell title="累计运行" :value="item.allTime" />
      <van-cell title="间隔周期" :value="item.intervalPeriod" />
      <van-cell title="周期单位" :value="item.periodUnitName" />
      <van-cell title="实施提前提醒时间" :value="item.warningCycle" />
      <van-cell title="计划产生提前时间" :value="item.planProduceAdvanceDays" />
      <van-cell title="允许延期" :value="item.delayDay" />
      <van-cell title="计划归属" :value="item.planOwnershipName" />
      <van-cell title="最近执行日期" :value="item.lastDate" />
      <van-cell title="下次执行日期" :value="item.nextDate" />
      <van-cell title="备注" type="textarea" :value="item.remark" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EquipmentMaintainPlanDetail",
  data() {
    return {
      item: {}
    };
  },
  created() {
    this.$nextTick(() => {
      this.item = this.$route.params.item;
    });
  },
  methods: {
    navBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  height: 750px;
  overflow: hidden;

  .container {
    height: 750px;
    margin-top: 58px;
  }
}
</style>
